import { useEffect, useState } from "react";
import CryptoPayPage from "./cryptoPay/index";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const fetchPageDataById = (co) => {
  return {
    title: co ? "App Tron" : "Ramaaz Pay Tron",
    description: co ? "App Tron" : "Ramaaz Pay Tron",
  };
};

export default function Main() {
  const [step, setStep] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [payToken, setPayToken] = useState(searchParams.get("token"));
  const co = searchParams.get("co");
  useEffect(() => {
    const { title, description } = fetchPageDataById(co);
    console.log(title, description);
    document.title = title;
    const metaTags = [
      { name: "description", content: description },
      { name: "title", content: title },
    ];

    // Using react-helmet to dynamically update metadata
    const helmet = (
      <Helmet>
        <title>{title}</title>
        {metaTags.map((tag, index) => (
          <meta key={index} {...tag} />
        ))}
      </Helmet>
    );

    // Render helmet in the component
    return () => {
      document.title = "App Tron Default";
    };
  }, [co]);

  useEffect(() => {
    if (searchParams.get("token")) {
      setPayToken(searchParams.get("token"));
      // setStep(4);
    }
  }, [searchParams]);

  // if (step === 1) {
  //   return (
  //     <LoadingPage
  //       goToNextStep={() => {
  //         setStep(2);
  //       }}
  //     />
  //   );
  // } else if (step === 2) {
  //   return (
  //     <Operations
  //       goToNextStep={() => {
  //         setStep(3);
  //       }}
  //     />
  //   );
  // } else if (step === 3) {
  //   return (
  //     <CryptoPay
  //       goToNextStep={(e) => {
  //         setStep(e);
  //       }}
  //       setPayToken={(e) => {
  //         setPayToken(e);
  //       }}
  //     />
  //   );
  // } else if (step === 4) {
  if (payToken) {
    return (
      <CryptoPayPage
        setSearchParams={setSearchParams}
        setPayToken={(token) => {
          setPayToken(token);
        }}
        goToNextStep={() => {
          // setStep(2);
        }}
        token={payToken}
      />
    );
  } else {
    return <div></div>;
  }
  // } else {
  //   return (
  //     <LoadingPage
  //       goToNextStep={() => {
  //         setStep(2);
  //       }}
  //     />
  //   );
  // }
}
