const api = "7c7b079c-a4ab-486b-8195-f5af1828da0e";

export const privateKey =
  "6f0caf9b6cfd021866c5108e4b3c419116175fa88924bca7c5346c5599022d1a"; // for TCrL9Eq7CNd9SghXnQvtthPLHJJc8cwyb1 computer wallet tronlonk
// "cacdd7dec38227054327e622422f8c0f9f0b3427c91adcf7c8fd70c78861d753";

// window.tronWeb1 = tronWeb;
const TronWeb = require("tronweb");
const HttpProvider = TronWeb.providers.HttpProvider;
// const fullNode = new HttpProvider("https://api.trongrid.io");
// const solidityNode = new HttpProvider("https://api.trongrid.io");
// const eventServer = new HttpProvider("https://api.trongrid.io");

const fullNode = !!process.env.REACT_APP_NILE
  ? new HttpProvider("https://nile.trongrid.io/")
  : new HttpProvider("https://api.trongrid.io");
const solidityNode = !!process.env.REACT_APP_NILE
  ? new HttpProvider("https://nile.trongrid.io/")
  : new HttpProvider("https://api.trongrid.io");
const eventServer = !!process.env.REACT_APP_NILE
  ? new HttpProvider("https://nile.trongrid.io/")
  : new HttpProvider("https://api.trongrid.io");

export const tronWeb = new TronWeb(
  fullNode,
  solidityNode,
  eventServer,
  privateKey
);
tronWeb.setHeader({ "TRON-PRO-API-KEY": api });
