export default function Crypto({ color }) {
    return (
        <svg id="Group_11972" data-name="Group 11972" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path id="Path_21915" data-name="Path 21915" d="M12.516,10A2.516,2.516,0,1,0,10,12.516,2.52,2.52,0,0,0,12.516,10ZM10,11.533A1.533,1.533,0,1,1,11.533,10,1.536,1.536,0,0,1,10,11.533Z" fill={color ? color : "#8e8e8e"} />
            <path id="Path_21916" data-name="Path 21916" d="M6.357,9.508A.492.492,0,1,0,6.848,10,.493.493,0,0,0,6.357,9.508Z" fill={color ? color : "#8e8e8e"} />
            <path id="Path_21917" data-name="Path 21917" d="M4.254,10a.492.492,0,1,0,.492-.492A.493.493,0,0,0,4.254,10Z" fill={color ? color : "#8e8e8e"} />
            <path id="Path_21918" data-name="Path 21918" d="M3.627,10a1.814,1.814,0,1,0-1.811,1.816A1.819,1.819,0,0,0,3.627,10Zm-1.811.832A.832.832,0,1,1,2.648,10,.833.833,0,0,1,1.816,10.832Z" fill={color ? color : "#8e8e8e"} />
            <circle id="Ellipse_427" data-name="Ellipse 427" cx="0.492" cy="0.492" r="0.492" transform="translate(14.762 9.508)" fill={color ? color : "#8e8e8e"} />
            <path id="Path_21919" data-name="Path 21919" d="M13.643,10.492A.492.492,0,1,0,13.152,10,.493.493,0,0,0,13.643,10.492Z" fill={color ? color : "#8e8e8e"} />
            <path id="Path_21920" data-name="Path 21920" d="M18.184,8.184A1.816,1.816,0,1,0,20,10,1.819,1.819,0,0,0,18.184,8.184Zm0,2.648A.832.832,0,1,1,19.016,10,.833.833,0,0,1,18.184,10.832Z" fill={color ? color : "#8e8e8e"} />
            <path id="Path_21921" data-name="Path 21921" d="M10,13.152a.492.492,0,1,0,.492.492A.493.493,0,0,0,10,13.152Z" fill={color ? color : "#8e8e8e"} />
            <path id="Path_21922" data-name="Path 21922" d="M10,14.762a.492.492,0,1,0,.492.492A.493.493,0,0,0,10,14.762Z" fill={color ? color : "#8e8e8e"} />
            <path id="Path_21923" data-name="Path 21923" d="M10,16.373a1.814,1.814,0,1,0,1.816,1.811A1.819,1.819,0,0,0,10,16.373Zm0,2.643a.832.832,0,1,1,.832-.832A.833.833,0,0,1,10,19.016Z" fill={color ? color : "#8e8e8e"} />
            <path id="Path_21924" data-name="Path 21924" d="M10,6.848a.492.492,0,1,0-.492-.492A.493.493,0,0,0,10,6.848Z" fill={color ? color : "#8e8e8e"} />
            <circle id="Ellipse_428" data-name="Ellipse 428" cx="0.492" cy="0.492" r="0.492" transform="translate(9.508 4.254)" fill={color ? color : "#8e8e8e"} />
            <path id="Path_21925" data-name="Path 21925" d="M10,3.627A1.814,1.814,0,1,0,8.184,1.816,1.819,1.819,0,0,0,10,3.627ZM10,.984a.832.832,0,1,1-.832.832A.833.833,0,0,1,10,.984Z" fill={color ? color : "#8e8e8e"} />
            <path id="Path_21926" data-name="Path 21926" d="M7.078,12.23a.493.493,0,1,0,.7,0A.492.492,0,0,0,7.078,12.23Z" fill={color ? color : "#8e8e8e"} />
            <circle id="Ellipse_429" data-name="Ellipse 429" cx="0.492" cy="0.492" r="0.492" transform="translate(5.795 13.221)" fill={color ? color : "#8e8e8e"} />
            <path id="Path_21927" data-name="Path 21927" d="M2.93,14.5a1.814,1.814,0,1,0,2.566,0A1.813,1.813,0,0,0,2.93,14.5ZM4.8,16.377a.829.829,0,1,1,0-1.172A.829.829,0,0,1,4.8,16.377Z" fill={color ? color : "#8e8e8e"} />
            <circle id="Ellipse_430" data-name="Ellipse 430" cx="0.492" cy="0.492" r="0.492" transform="translate(13.221 5.795)" fill={color ? color : "#8e8e8e"} />
            <circle id="Ellipse_431" data-name="Ellipse 431" cx="0.492" cy="0.492" r="0.492" transform="translate(12.086 6.93)" fill={color ? color : "#8e8e8e"} />
            <path id="Path_21928" data-name="Path 21928" d="M17.07,5.5a1.814,1.814,0,1,0-2.566,0A1.813,1.813,0,0,0,17.07,5.5ZM15.2,3.623a.829.829,0,1,1,0,1.172A.829.829,0,0,1,15.2,3.623Z" fill={color ? color : "#8e8e8e"} />
            <circle id="Ellipse_432" data-name="Ellipse 432" cx="0.492" cy="0.492" r="0.492" transform="translate(13.221 13.221)" fill={color ? color : "#8e8e8e"} />
            <path id="Path_21929" data-name="Path 21929" d="M12.23,12.23a.493.493,0,1,0,.7,0A.492.492,0,0,0,12.23,12.23Z" fill={color ? color : "#8e8e8e"} />
            <path id="Path_21930" data-name="Path 21930" d="M14.5,14.5a1.814,1.814,0,1,0,2.566,0A1.813,1.813,0,0,0,14.5,14.5Zm1.873,1.873a.829.829,0,1,1,0-1.172A.829.829,0,0,1,16.377,16.377Z" fill={color ? color : "#8e8e8e"} />
            <circle id="Ellipse_433" data-name="Ellipse 433" cx="0.492" cy="0.492" r="0.492" transform="translate(6.93 6.93)" fill={color ? color : "#8e8e8e"} />
            <circle id="Ellipse_434" data-name="Ellipse 434" cx="0.492" cy="0.492" r="0.492" transform="translate(5.795 5.795)" fill={color ? color : "#8e8e8e"} />
            <path id="Path_21931" data-name="Path 21931" d="M5.5,5.5a1.814,1.814,0,1,0-2.566,0A1.813,1.813,0,0,0,5.5,5.5ZM3.623,3.623a.829.829,0,1,1,0,1.172A.829.829,0,0,1,3.623,3.623Z" fill={color ? color : "#8e8e8e"} />
        </svg>
    )
}