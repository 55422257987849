import { useEffect, useMemo, useState } from "react";
import { WalletProvider } from "@tronweb3/tronwallet-adapter-react-hooks";
import { WalletConnectAdapter } from "@tronweb3/tronwallet-adapter-walletconnect";
import axios from "axios";
import {
  WalletDisconnectedError,
  WalletNotFoundError,
} from "@tronweb3/tronwallet-abstract-adapter";
import { TronLinkAdapter } from "@tronweb3/tronwallet-adapters";
import { WalletModalProvider } from "@tronweb3/tronwallet-adapter-react-ui";
import Buy from "./pay";
import LoadingConnect from "./loadingConnect";

const projectId = process.env.REACT_APP_PROJECT_ID;

const IndexBuy = ({ token, goToNextStep, setPayToken, setSearchParams }) => {
  console.log("IndexBuy");
  const [amountEntered, setAmountEntered] = useState(0);
  const [isPaid, setIsPaid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedContract, setSelectedContract] = useState({
    id: null,
    address: null,
    abi: null,
    icon: null,
    name: null,
  });
  const [error, setError] = useState(null);

  const _fetchTokenData = async (token) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_WALLET_URL}api/v1/crypto_transactions/show`,
        { token },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res, "response");
      const response = res?.data?.data;
      if (response?.status === 2) {
        setIsPaid(true);
      }
      if (response?.crypto_contract.crypto_chain) {
        setAmountEntered(response?.amount);
        setSelectedContract({
          id: 1,
          address: response?.crypto_contract.hash,
          abi: response?.crypto_contract.abi,
          icon: response?.crypto_contract.icon_path,
          name: response?.crypto_contract.name,
          chainId: response?.crypto_contract.crypto_chain.ChainID,
          symbol: response?.crypto_contract.name,
          decimals: response?.crypto_contract.decimal,
          chainName: response?.crypto_contract.crypto_chain.chainName,
          blockExplorerUrls:
            response?.crypto_contract.crypto_chain.blockExplorerUrls,
          rpcUrls: response?.crypto_contract.crypto_chain.rpcUrls,
          adminWallet: response?.crypto_contract.admin_wallet_address,
          NetworkID: response?.crypto_contract.crypto_chain.NetworkID,
          status: response?.status,
          client: response?.client,
          trading_number: response?.trading_number,
          ticket_operating_unit_name: response?.ticket_operating_unit_name,
          transaction_info_explore_url: response?.transaction_info_explore_url,
          public_key: response?.public_key,
        });
      }
      setSearchParams({ token: `${token}` });
    } catch (e) {
      setPayToken(null);
      // window.location.href = "/";
    }
    setLoading(false);
    // console.log(response, "res");
  };

  useEffect(() => {
    if (token) {
      _fetchTokenData(token);
    }
  }, [token]);

  function onError(e) {
    if (e instanceof WalletNotFoundError) {
      setError({
        type: "error",
        message: e.message,
      });
    } else if (e instanceof WalletDisconnectedError) {
      setError({
        type: "error",
        message: e.message,
      });
    } else {
      setError({
        type: "error",
        message: e.message,
      });
    }
  }

  const REACT_APP_NILE = process.env.REACT_APP_NILE === "true";

  const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };

  const adapters = useMemo(() => {
    const walletConnectAdapter = new WalletConnectAdapter({
      network: REACT_APP_NILE ? "Nile" : "Mainnet", // 'Mainnet' | 'Shasta' | 'Nile';
      options: {
        relayUrl: "wss://relay.walletconnect.com",
        // example WC app project ID
        projectId: "d98a2315aa4ffe14560c397a54becd18",
        metadata: {
          name: "Ramaaz Pay Tron",
          description: " Ramaaz Pay Tron",
          url: process.env.REACT_APP_REDIRECT_URL,
          icons: [
            `${process.env.REACT_APP_REDIRECT_URL}/assets/ramaaz.svg`,
            "/assets/ramaaz.svg",
          ],
        },
      },
      web3ModalConfig: {
        themeMode: "light",
        themeVariables: {
          "--w3m-z-index": "1000",
          "--w3m-button-border-radius": "15px",
          "--w3m-font-family": "SF-Pro-Rounded-regular",
          "--w3m-background-color": "#FAFAFA",
          "--w3m-secondary-button-border-radius": "15px",
        },
        explorerRecommendedWalletIds: [
          "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0", // trust wallet id
        ],
        allWallets: "HIDE",
      },
    });

    if (isMobile()) {
      return [walletConnectAdapter];
    } else {
      const tronLinkAdapter = new TronLinkAdapter({
        openUrlWhenWalletNotFound: true,
        dappIcon: `${process.env.REACT_APP_REDIRECT_URL}/assets/ramaaz.svg`,
        dappName: "Ramaaz Pay Tron",
      });
      return [tronLinkAdapter, walletConnectAdapter];
    }
  }, [REACT_APP_NILE]);

  if (loading) {
    return <LoadingConnect goToNextStep={() => console.log("funny!")} />;
  } else {
    return (
      <WalletProvider
        onError={onError}
        autoConnect={true}
        disableAutoConnectOnLoad={true}
        adapters={adapters}
      >
        <WalletModalProvider>
          <Buy
            error={error}
            goToNextStep={goToNextStep}
            amountEntered={amountEntered}
            isPaid={isPaid}
            loading={loading}
            selectedContract={selectedContract}
            token={token}
            setLoading={(a) => {
              setLoading(a);
            }}
          />
        </WalletModalProvider>
      </WalletProvider>
    );
  }
};

export default IndexBuy;
