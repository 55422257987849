import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import { Toaster } from "react-hot-toast";
import "@tronweb3/tronwallet-adapter-react-ui/style.css";
import App from "./App";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://76d1e2b4cbaf3f8bf4d2473846e74182@o4506523348172800.ingest.sentry.io/4506840504991744",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/vision-pay-tron.vercel.app\.io\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <ThemeProvider theme={theme}> */}
    <App />
    <Toaster />
    {/* </ThemeProvider> */}
  </React.StrictMode>
);
