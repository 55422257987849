export const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        "/service-worker.js",
        {
          scope: "./",
        }
      );
      if (registration.installing) {
        console.log("Service worker installing");
      } else if (registration.waiting) {
        console.log("Service worker installed");
      } else if (registration.active) {
        console.log("Service worker active");
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};
export const _requestShowNotification = async () => {
  return await Notification.requestPermission(function (result) {
    if (result === "granted") {
    } else {
      alert("You Blocked Notification For This Site");
    }
  });
};
export const sendNotification = async (message) => {
  const options = {
    body: message,
    icon: "/ramaazz.ico",
    silent: false,
    vibrate: [200, 100, 200, 100, 200, 100, 200],
    tag: "Message",
    timestamp: Date.now(),
  };
  const accept = await _requestShowNotification();
  console.log(accept, "accept");
  if (accept === "granted") {
    navigator.serviceWorker.ready.then(function (registration) {
      registration.showNotification("Ramaaz Pay", options);
    });
  }
};
