import axios from "axios";

export const getMiddleWallet = async (token) => {
  let data = null;
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_WALLET_URL}api/v1/crypto_transactions/link_wallet`,
      { token },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    );
    data = res?.data?.data;
    console.log(data, "data");
  } catch (e) {
    console.log(e);
  }
  return data;
};
