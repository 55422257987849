import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Slide, ToastContainer } from "react-toastify";
import "./App.css";
import Main from "./Pages/main";
import {
  _requestShowNotification,
  registerServiceWorker,
} from "./components/sendNotification";
// 1. Get projectId at https://cloud.walletconnect.com

function App() {
  registerServiceWorker();
  _requestShowNotification();
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Main />} />
          <Route path="*" element={<Main />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        pauseOnHover
        hideProgressBar
        autoClose={2000}
        closeOnClick
        rtl={false}
        closeButton={false}
        icon={true}
        transition={Slide}
        theme="light"
        toastClassName={
          "!bg-[#404040e8] !text-[#f2f2f2] h-12 w-full flex items-center justify-center"
        }
        bodyClassName="w-max max-w-100 h-12 text-sm text-center"
        position="bottom-center"
      />
    </>
  );
}

export default App;
